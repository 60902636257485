import { PiUpload } from "react-icons/pi";
import "./upload-button.scss";

export default function UploadButton({onchange}) {
    return (
        <div className="upload-button">
            <input type="file" 
            className="upload-button-input" 
            onChange={onchange}
            accept=".png .jpeg .jpg"
             />
            <div>
                <span>
                    <PiUpload />
                </span>
                <p>Import</p>
            </div>
        </div>
    )
}
