import React, { Children, Fragment } from 'react';
import "./table-comp.scss";

export default function TableComp({ header, body }) {
    return (
        <div className="table">
            <div className="thead">
                {Children.map(header, (child, index) => (
                    <Fragment key={index}>
                        {child}
                    </Fragment>
                ))}
            </div>
            <div className="tbody">
                {Children.map(body, (child, index) => (
                    <Fragment key={index}>
                        {child}
                    </Fragment>
                ))}
            </div>
        </div>
    );
}