import TableComp from "../Components/TableComp";
import "./product.scss";
import { PiHash, PiList, PiCheck, PiPencilSimple, PiCopy, PiArrowSquareOut, PiTrayArrowDown, PiTrash, PiArrowsClockwise, PiTilde, PiTrayArrowUp, PiPlus, PiX } from 'react-icons/pi';
import { ReactComponent as CheckIcon } from "../assets/check.svg"
import { ReactComponent as ArrowLeftIcon } from "../assets/arrow-left.svg"
import { useCallback, useEffect, useState } from "react";
import config from "../config"
import UploadButton from "../Components/UploadButton";
import Input from "../Components/Input";
import DropDown from "../Components/DropDown";

export default function Products() {
    const [products, setProducts] = useState([])
    const [isCheckedProduct, setIsCheckedProduct] = useState([])
    const [productId, setProductId] = useState("")
    const [isCopied, setIsCopied] = useState({});
    const [isModifyStatus, setIsModifyStatus] = useState([])
    const [isOpenList, setIsOpenList] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [mediaIds, setMediaIds] = useState([]);
    const [imagesUploaded, setImagesUploaded] = useState([])
    const [isOpenImagesList, setIsOpenImagesList] = useState([])
    const [refreshImages, setRefreshImages] = useState(false);
    const [values, setValues] = useState([]);
    const [isEditField, setIsEditField] = useState([]);
    const [file, setFile] = useState([])
    const [message, setMessage] = useState(null)
    const [images, setImages] = useState([]);
    const [mediaId, setMediaId] = useState(null)
    const [theme, setTheme] = useState("");
    const [option, setOption] = useState("all")
    const [isNewProduct, setIsNewProduct] = useState(false)
    const [productName, setProductName] = useState("");
    const [productDescrp, setProductDescrp] = useState("");
    const [productCateg, setProductCateg] = useState("");
    const [isOpenGalery, setIsOpenGalery] = useState(false);
    const [productMediaId, setProductMediaId] = useState(null);
    const [page, setPage] = useState(1)
    const limit = 10;

    useEffect(() => {
        const params = new URLSearchParams();
        params.set("option", option);
        fetch(config.API_BASE + "/products/list?" + params.toString(), {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include"
        })
            .then(response => response.json())
            .then(data => {
                setProducts(data)
                setIsCheckedProduct(new Array(data.length).fill(false))
                setIsModifyStatus(new Array(data.length).fill(false))
                setIsOpenList(new Array(data.length).fill(false))
                setIsOpenImagesList(new Array(data.length).fill(false))
                setValues(data.map(d => {
                    return (
                        Object.entries(d).map(([k, v]) => {
                            return { k, v }
                        })
                    )
                }))
                setIsEditField(new Array(data.length).fill(null).map(() =>
                    new Array(Object.keys(data[0]).length).fill(false)
                ))
            })
            .catch(err => console.log(err))
    }, [refresh, option])

    useEffect(() => {
        fetch(config.API_BASE + "/media/list", {
            method: "GET",
            credentials: "include"
        }).then(response => response.json())
            .then(data => {
                setMediaIds(data)
            }).catch(err => console.log(err))
    }, [refreshImages])

    const fetchImages = useCallback(async () => {
        try {
            const imageFetches = products.map(async (product) => {
                const response = await fetch(config.API_BASE + `/products/media?productId=${product?.productId}`, {
                    method: "GET",
                    credentials: "include"
                });
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                return { [product.productId]: url };
            });

            const imageObjects = await Promise.all(imageFetches);
            const imageMap = imageObjects.reduce((acc, imageObject) => {
                return { ...acc, ...imageObject };
            }, {});

            setImages(imageMap);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    }, [products]);

    const fetchImagesUpladed = useCallback(async () => {
        try {
            const imageFetches = mediaIds?.map(async (mediaId) => {
                const response = await fetch(config.API_BASE + `/media/?mediaId=${mediaId}`, {
                    method: "GET",
                    credentials: "include"
                });
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);
                return { [mediaId]: url };
            });

            const imageObjects = await Promise.all(imageFetches);
            const imageMap = imageObjects.reduce((acc, imageObject) => {
                return { ...acc, ...imageObject };
            }, {});

            setImagesUploaded(imageMap);
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    }, [mediaIds]);

    useEffect(() => {
        fetchImages()
        fetchImagesUpladed()
    }, [fetchImages, fetchImagesUpladed])
    const onSelectProducts = (index, leadId) => {
        setIsCheckedProduct(prev => {
            const newValues = [...prev];
            newValues[index] = !newValues[index];
            return newValues;
        });
        setProductId(leadId)
    };

    const onCopyValue = (text, fieldKey, index) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setIsCopied({ field: fieldKey, index });
                setTimeout(() => setIsCopied({}), 3000);
            })
            .catch((err) => console.error('Failed to copy: ', err));
    };

    const onPublishProduct = (productId) => {
        fetch(config.API_BASE + "/products/publish", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({ productId: productId })
        }).then(res => res.json())
            .then(data => {
                if (data.success) {
                    setMessage("Product Published Successfully");
                    setTimeout(() => {
                        setMessage(null);
                    }, 3000)
                    setRefresh(r => !r)
                }
                else {
                    setMessage("Failed to publish product");
                    setTimeout(() => {
                        setMessage(null);
                    }, 3000)
                }
            })
    }
    const onUnpublishProduct = (productId) => {
        fetch(config.API_BASE + "/products/unpublish", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({ productId: productId })
        }).then(res => res.json())
            .then(data => {
                if (data.success) {
                    setMessage("Product Unpublished Successfully");
                    setTimeout(() => {
                        setMessage(null);
                    }, 3000)
                    setRefresh(r => !r)
                }
                else {
                    setMessage("Failed to unpublish product");
                    setTimeout(() => {
                        setMessage(null);
                    }, 3000)
                }
            })
    }

    const onModifyStatus = (e, index) => {
        e.stopPropagation();
        setIsModifyStatus(prev => {
            let newValues = prev.map((item, i) => (i === index ? !item : false));
            return newValues;
        });
    }

    const onOpenList = (e, index) => {
        e.stopPropagation()
        setIsOpenList(prev => {
            let newValues = prev.map((item, i) => (i === index ? !item : false));
            return newValues;
        });
    }

    const onDeleteProduct = async (e, productId) => {
        e.stopPropagation()
        await fetch(config.API_BASE + "/products/delete", {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ productId: productId })
        }).then(response => response.json())
            .then(data => {
                if (data.success) {
                    setMessage("Product Deleted Successfully");
                    setTimeout(() => {
                        setMessage(null);
                    }, 3000)
                    setRefresh(p => !p)
                }
                else {
                    setMessage("Failed to delete product");
                    setTimeout(() => {
                        setMessage(null);
                    }, 3000)
                }
            })
            .catch(err => console.log(err))
    }

    const onOpenImagesList = (e, index, id) => {
        e.stopPropagation()
        setIsOpenImagesList(prev => {
            let newValues = prev.map((item, i) => (i === index ? !item : false));
            return newValues;
        });
        setProductId(id)
    }

    const onUploadMedia = (e, file) => {
        e.stopPropagation();
        const formData = new FormData();
        formData.append('file', file);
        fetch(config.API_BASE + "/media/upload", {
            method: "POST",
            body: formData,
            credentials: "include"
        }).then(response => response.json())
            .then(data => {
                console.log(data);
                setMessage('Images uploaded succesfully')
                setTimeout(() => {
                    setMessage(null)
                }, 3000)
                setFile([{ imgId: data.data.mediaId, nameImg: file.name }])
                setRefreshImages(p => !p)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        if (file.length === 0) {
            return;
        }
        setMediaId(file[0].imgId)
    }, [file])

    useEffect(() => {
        if (!productId || !mediaId) {
            return;
        }

        fetch(config.API_BASE + "/products/edit/media", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({ productId: productId, mediaId: mediaId })
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setMessage("Product image updated successfully")
                    setTimeout(() => {
                        setMessage(null)
                    }, 3000)
                    setMediaId(null)
                    setRefresh(r => !r)
                }
                else {
                    setMessage("Error updating product image")
                    setTimeout(() => {
                        setMessage(null)
                    }, 3000)
                }
            })
            .catch(err => console.log(err))
    }, [productId, mediaId])

    const onEditField = (e, productIndex, fieldIndex) => {
        e.stopPropagation();
        setIsEditField(() => {
            return isEditField.map((row, index) => {
                return index === productIndex
                    ? row.map(() => false).fill(false, 0, row.length).fill(true, fieldIndex, fieldIndex + 1)
                    : row.map(() => false);
            });
        });
    }

    const handleChangeFieldValue = (e, productIndex, fieldIndex) => {
        setValues(prev => {
            const newValue = [...prev];
            newValue[productIndex][fieldIndex] = e.target.value
            return newValue
        })
    }

    const onEditNameValue = (e, productId, productIndex, fieldIndex) => {
        e.stopPropagation()
        fetch(config.API_BASE + "/products/edit/name", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({ productId: productId, name: values[productIndex][fieldIndex] })
        }).then(
            response => response.json()
        )
            .then(data => {
                console.log(data)
                setRefresh(r => !r)
                setIsEditField(new Array(products.length).fill(null).map(() =>
                    new Array(Object.keys(products[0]).length).fill(false)))
                setValues(products.map(() => {
                    return (
                        Object.entries(products[0]).map(([k, v]) => {
                            return { k, v }
                        })
                    )
                }))
                setMessage('PRODUCT Name UPDATED SUCCESSFULLY')
                setTimeout(() => {
                    setMessage(null)
                }, 3000)
            })
            .catch(err => console.log(err))
    }

    const onEditDescriptionValue = (e, productId, productIndex, fieldIndex) => {
        e.stopPropagation()
        fetch(config.API_BASE + "/products/edit/description", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({ productId: productId, description: values[productIndex][fieldIndex] })
        }).then(
            response => response.json()
        )
            .then(data => {
                console.log(data)
                setRefresh(r => !r)
                setIsEditField(new Array(products.length).fill(null).map(() =>
                    new Array(Object.keys(products[0]).length).fill(false)))
                setValues(products.map(() => {
                    return (
                        Object.entries(products[0]).map(([k, v]) => {
                            return { k, v }
                        })
                    )
                }))
                setMessage('PRODUCT DESCRIPTION UPDATED SUCCESSFULLY')
                setTimeout(() => {
                    setMessage(null)
                }, 3000)
            })
            .catch(err => console.log(err))
    }

    const onEditCategoryValue = (e, productId, productIndex, fieldIndex) => {
        e.stopPropagation()
        fetch(config.API_BASE + "/products/edit/category", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            credentials: "include",
            body: JSON.stringify({ productId: productId, category: values[productIndex][fieldIndex] })
        }).then(
            response => response.json()
        )
            .then(data => {

                setRefresh(r => !r)
                setIsEditField(new Array(products.length).fill(null).map(() =>
                    new Array(Object.keys(products[0]).length).fill(false)))
                setValues(products.map(() => {
                    return (
                        Object.entries(products[0]).map(([k, v]) => {
                            return { k, v }
                        })
                    )
                }))
                if (data.error) {
                    setMessage('Error updating category')
                    setTimeout(() => {
                        setMessage(null)
                    }, 3000)
                }
                else {
                    setMessage('PRODUCT CATEGORY UPDATED SUCCESSFULLY')
                    setTimeout(() => {
                        setMessage(null)
                    }, 3000)

                }
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        if (isCheckedProduct.every(item => item === true)) {
            setTheme("all");
        } else if (isCheckedProduct.some(item => item === true)) {
            setTheme("almost");
        }
        else {
            setTheme("");
        }
    }, [isCheckedProduct]);

    const handleSelectAllProduct = useCallback(() => {
        setIsCheckedProduct(prevState => {
            const allChecked = prevState.every(item => item === true);
            return prevState.map(() => !allChecked);
        });
    }, [setIsCheckedProduct]);

    const getOption = (option) => {
        setOption(option)
    }

    const onAddPage = () => {
        setPage(page + 1)
    }

    const onMinusPage = () => {
        setPage(page - 1)
    }

    const onArchivProduct = (e, productId) => {
        e.stopPropagation();
        const archiveTarget = config.API_BASE + "/products/archive";
        fetch(archiveTarget, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                productId: productId
            })
        }).then(response => {
            response.json()
            setRefresh(!refresh)
            setMessage("product archived successfully")
            setTimeout(() => {
                setMessage(null)
            }, 4000)
        })
            .catch(err => {
                console.error(err);
            })
    }

    const onUnarchivProduct = (e, productId) => {
        e.stopPropagation();
        const archiveTarget = config.API_BASE + "/products/unarchive";
        fetch(archiveTarget, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                productId: productId
            })
        }).then(response => {
            response.json()
            setRefresh(!refresh)
            setMessage("product unarchived successfully")
            setTimeout(() => {
                setMessage(null)
            }, 4000)
        })
            .catch(err => {
                console.error(err);
            })
    }

    const onCreateNewProduct = () => {
        const body = {
            name: productName,
            description: productDescrp || "empty description",
            category: productCateg || "life",
        };
        if (productMediaId) {
            body.mediaId = productMediaId;
        }
        fetch(config.API_BASE + "/products/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(body)
        }).then(res => res.json())
            .then(data => {
                setIsNewProduct(false)
                setRefresh(!refresh)
                if (data.success) {
                    setMessage(data.success)
                    setTimeout(() => {
                        setMessage(null)
                    }, 3000)
                }
                else {
                    setMessage(data.error)
                    setTimeout(() => {
                        setMessage(null)
                    }, 3000)
                }
                setProductCateg("")
                setProductDescrp("")
                setProductName("")
                setProductMediaId(null)
                setIsOpenGalery(false)
            })
    }
    const [listCategories, setListCategories] = useState([]);
    useEffect(() => {
        fetch(config.API_BASE + "/products/categories-list", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include"
        }).then(res => res.json())
            .then(data => {
                setListCategories(data)
            })
            .catch(err => console.log(err))
    }, [])
    const [isCheckedCateg, setIsCheckCateg] = useState([])
    const onSelectCateg = (categ, index) => {
        setProductCateg(categ)
        setIsCheckCateg((prev) => {
            const newValues = prev.map((item, i) => (i === index ? !item : false));
            const hasTrueValue = newValues.some(value => value === true);
            return hasTrueValue ? newValues : prev;
        })
    }
    const [isOpenCategList, setIsOpenCategList] = useState(false);

    const toggleDropdown = () => setIsOpenCategList(!isOpenCategList);

    console.log(listCategories)
    return (
        <div className="products">
            <div className="sub-nav-bar">
                <div className="new-prod">
                    {
                        !isNewProduct
                        &&
                        <button className="add-new-prod" onClick={() => { setIsNewProduct(true) }}>
                            <span><PiPlus size={"16px"} color="var(--blue)" /></span>
                            Add new product
                        </button>
                    }
                    {
                        isNewProduct &&
                        <>
                            <button className="confirm" onClick={onCreateNewProduct}>
                                <span><PiCheck size={"16px"} color="var(--blue)" /></span>
                                Confirm
                            </button>
                            <button className="cancel"
                                onClick={() => {
                                    setIsNewProduct(false)
                                    setProductCateg("")
                                    setProductDescrp("")
                                    setProductName("")
                                    setProductMediaId(null)
                                }}>
                                <span><PiX size={"16px"} color="var(--dark-grey)" /></span>
                                Cancel
                            </button>
                        </>
                    }
                </div>
                <div className="filter">
                    <div className={option === "main" ? "active" : ""} onClick={() => getOption("main")}>Main
                        <span>
                            ({products.length})
                        </span>
                    </div>
                    <div className={option === "archived" ? "active" : ""} onClick={() => getOption("archived")}>Archive
                        <span>
                            ({products.length})
                        </span>
                    </div>
                    <div className={option === "all" ? "active" : ""} onClick={() => getOption("all")}>All
                        <span>
                            ({products.length})
                        </span>
                    </div>
                </div>
                <div className="pagination">
                    <p>Page 1 of 1</p>
                    <div>
                        <span className={page === 1 ? "left disabled" : "left"} onClick={onMinusPage}><ArrowLeftIcon /></span>
                        <span className={products.length < limit ? "right disabled" : "right"} onClick={onAddPage}><ArrowLeftIcon /></span>
                    </div>
                </div>
            </div>
            <TableComp
                header={
                    <>
                        <div className="first">
                            <div className="check">
                                <span className={theme} onClick={handleSelectAllProduct}>
                                    {
                                        theme === "almost" ?
                                            <PiTilde size={"16px"} color="var(--blue)" />
                                            :
                                            <PiCheck
                                                size={"16px"}
                                                color={theme === "all" ? "var(--blue)" : "#CCC"}
                                            />
                                    }
                                </span>
                            </div>
                            <div className="list">
                                <span>
                                    <PiList size={"16px"} />
                                </span>
                            </div>
                            <div className="number">
                                <span><PiHash size={"16px"} /></span>
                            </div>
                        </div>
                        <div className="separator">
                            <div></div>
                        </div>
                        <div className="second">
                            <div className="info">
                                <p>Product Name</p>
                            </div>
                            <div className="info">
                                <p>Description</p>
                            </div>
                            <div className="info">
                                <p>Image</p>
                            </div>
                            <div className="info">
                                <p>Category</p>
                            </div>
                        </div>
                        <div className="separator">
                            <div></div>
                        </div>
                        <div className="last">
                            <div className="status">
                                <p>Status</p>
                            </div>
                            <div className="created-at">
                                <p>Created at</p>
                            </div>
                        </div>
                    </>
                }
                body={
                    <>
                        {
                            isNewProduct === true &&
                            <div className="product-line add-new">
                                <div className="first">
                                    <div className="check">
                                        <span>
                                            <div className="checkbox">
                                                <CheckIcon />
                                            </div>
                                        </span>
                                    </div>
                                    <div className="list">
                                        <span>
                                            <PiList color="var(--dark-grey)" />
                                        </span>
                                    </div>
                                    <div className="number">
                                        <span></span>
                                    </div>
                                </div>
                                <div className="separator">
                                    <div></div>
                                </div>
                                <div className="second">
                                    <div className="info">
                                        <div className="input">
                                            <input
                                                type="text"
                                                placeholder={"new product"}
                                                value={productName}
                                                onChange={(e) => { setProductName(e.target.value) }}
                                            />
                                            <span>
                                                <PiPencilSimple color="var(--dark-grey)" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="info">
                                        <div className="input">
                                            <input
                                                type="text"
                                                placeholder={"new descp"}
                                                value={productDescrp}
                                                onChange={(e) => { setProductDescrp(e.target.value) }}
                                            />
                                            <span >
                                                <PiPencilSimple color="var(--dark-grey)" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="info img">
                                        <div>
                                            <p>
                                                <img src={imagesUploaded[productMediaId] ? imagesUploaded[productMediaId] : ""} alt="" />
                                            </p>
                                            <div className="icons">
                                                <span onClick={() => { setIsOpenGalery(o => !o) }}>
                                                    <PiArrowsClockwise
                                                        fontWeight={"400"}
                                                        size={"16px"}
                                                        color="var(--dark-grey)" />
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            isOpenGalery &&
                                            <div className="images" onClick={(e) => { e.stopPropagation() }}>
                                                <div className="current-img">
                                                    <img src={imagesUploaded[productMediaId]} alt="" />
                                                    <UploadButton
                                                        onchange={(e) => onUploadMedia(e, e.target.files[0])}
                                                    />
                                                </div>
                                                <div className="other-imgs">
                                                    {
                                                        mediaIds.map((mediaId, i) => (
                                                            <div className="image" key={i}>
                                                                <img
                                                                    src={imagesUploaded[mediaId]}
                                                                    alt={mediaId}
                                                                    onClick={(e) => {
                                                                        const clickedImgId = e.target.alt;
                                                                        setProductMediaId(clickedImgId)
                                                                        setIsOpenGalery(false)
                                                                    }}
                                                                />
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="info">
                                        {/* <input
                                                type="text"
                                                placeholder={"new category"}
                                                value={productCateg}
                                                onChange={(e) => { setProductCateg(e.target.value) }}
                                            /> */}
                                        <DropDown
                                            options={listCategories}
                                            selectedOption={productCateg}
                                            handleOptionClick={onSelectCateg}
                                            isCheck={isCheckedCateg}
                                            setIsOpen={setIsOpenCategList}
                                            isOpen={isOpenCategList}
                                            toggleDropdown={toggleDropdown}
                                        />

                                    </div>
                                </div>
                                <div className="separator">
                                    <div></div>
                                </div>
                                <div className="last">
                                    <div className="status">
                                        <div>
                                            <div className="point" style={{ backgroundColor: "red" }}></div>
                                            offline
                                            <span>
                                                <PiPencilSimple />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="created-at">
                                        <div>
                                            <p>04/10/2024</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            products?.map((product, index) => (
                                <>

                                    <div className={isCheckedProduct[index] ? "product-line active" : "product-line"} key={index} onClick={() => onSelectProducts(index, product.productId)}>
                                        <div className="first">
                                            <div className="check">
                                                <span>
                                                    <div className="checkbox">
                                                        <CheckIcon />
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="list" onClick={(e) => onOpenList(e, index)}>
                                                <span className={isOpenList[index] ? "active" : ""}>
                                                    <PiList
                                                        color={isOpenList[index] ?
                                                            "var(--blue)" :
                                                            "var(--dark-grey)"} />
                                                </span>
                                                {
                                                    isOpenList[index] &&
                                                    <div className="list-items" onClick={(e) => { e.stopPropagation() }}>
                                                        <div className="item">
                                                            <span><PiArrowSquareOut size={"16px"} /> </span>
                                                            <p>
                                                                Export (csv)
                                                            </p>
                                                        </div>
                                                        {
                                                            product.isArchived === true ?
                                                                <div className="item" onClick={(e) => onUnarchivProduct(e, product.productId)}>
                                                                    <span><PiTrayArrowUp size={"16px"} /></span>
                                                                    <p>
                                                                        Unarchive
                                                                    </p>
                                                                </div>
                                                                :
                                                                <div className="item" onClick={(e) => onArchivProduct(e, product.productId)}>
                                                                    <span><PiTrayArrowDown size={"16px"} /></span>
                                                                    <p>
                                                                        Archive
                                                                    </p>
                                                                </div>
                                                        }

                                                        <div className="item delete" onClick={(e) => { onDeleteProduct(e, product.productId) }}>
                                                            <span><PiTrash size={"16px"} color="red" /></span>
                                                            <p>
                                                                Delete
                                                            </p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="number">
                                                <span>{product?.serialNumber}</span>
                                            </div>
                                        </div>
                                        <div className="separator">
                                            <div></div>
                                        </div>
                                        <div className="second">
                                            <div className="info">
                                                {
                                                    isEditField[index][0] ?
                                                        <Input
                                                            onClick={(e) => { e.stopPropagation() }}
                                                            onClose={(e) => {
                                                                e.stopPropagation()
                                                                setIsEditField(new Array(products.length).fill(null).map(() =>
                                                                    new Array(Object.keys(products[0]).length).fill(false)))
                                                            }}
                                                            value={
                                                                values[index] ?
                                                                    values[index][0].v
                                                                    : null
                                                            }
                                                            onchange={(e) => handleChangeFieldValue(e, index, 0)}
                                                            onConfirm={(e) => onEditNameValue(e, product.productId, index, 0)}
                                                        />
                                                        :
                                                        <div>
                                                            <p>{product?.productName}</p>
                                                            <div className="icons">
                                                                <span
                                                                    className={isCopied.index === index && isCopied.field === "productName" ? "active" : ""}
                                                                    onClick={
                                                                        (e) => {
                                                                            e.stopPropagation()
                                                                            onCopyValue(product.productName, 'productName', index)
                                                                        }}
                                                                >
                                                                    <PiCopy
                                                                        fontWeight={"400"}
                                                                        size={"16px"}
                                                                        color={isCopied.index === index && isCopied.field === "productName" ? "var(--blue)" : "var(--dark-grey)"} />
                                                                </span>
                                                                <span onClick={(e) => onEditField(e, index, 0)}>
                                                                    <PiPencilSimple
                                                                        fontWeight={"400"}
                                                                        size={"16px"}
                                                                        color="var(--dark-grey)" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            <div className="info">
                                                {
                                                    isEditField[index][4] ?
                                                        <Input
                                                            onClick={(e) => { e.stopPropagation() }}
                                                            onClose={(e) => {
                                                                e.stopPropagation()
                                                                setIsEditField(new Array(products.length).fill(null).map(() =>
                                                                    new Array(Object.keys(products[0]).length).fill(false)))
                                                            }}
                                                            value={
                                                                values[index] ?
                                                                    values[index][4].v
                                                                    : null
                                                            }
                                                            onchange={(e) => handleChangeFieldValue(e, index, 4)}
                                                            onConfirm={(e) => onEditDescriptionValue(e, product.productId, index, 4)}
                                                        />
                                                        :
                                                        <div>
                                                            <p>{product?.productDescription}</p>
                                                            <div className="icons">
                                                                <span
                                                                    className={isCopied.index === index && isCopied.field === "productDescription" ? "active" : ""}
                                                                    onClick={
                                                                        (e) => {
                                                                            e.stopPropagation()
                                                                            onCopyValue(product.productDescription, 'productDescription', index)
                                                                        }}
                                                                >
                                                                    <PiCopy
                                                                        fontWeight={"400"}
                                                                        size={"16px"}
                                                                        color={isCopied.index === index && isCopied.field === "productDescription" ? "var(--blue)" : "var(--dark-grey)"} />
                                                                </span>
                                                                <span onClick={(e) => onEditField(e, index, 4)}>
                                                                    <PiPencilSimple
                                                                        fontWeight={"400"}
                                                                        size={"16px"}
                                                                        color="var(--dark-grey)" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            <div className="info img">
                                                <div>
                                                    <p>
                                                        <img src={images[product?.productId]} alt="" />
                                                    </p>
                                                    <div className="icons">
                                                        <span
                                                            className={isOpenImagesList[index] ? "active" : ""}
                                                            onClick={(e) => onOpenImagesList(e, index, product.productId)}>
                                                            <PiArrowsClockwise
                                                                fontWeight={"400"}
                                                                size={"16px"}
                                                                color="var(--dark-grey)" />
                                                        </span>
                                                    </div>
                                                </div>
                                                {
                                                    isOpenImagesList[index] &&
                                                    <div className="images" onClick={(e) => { e.stopPropagation() }}>
                                                        <div className="current-img">
                                                            <img src={images[product?.productId]} alt="" />
                                                            <UploadButton
                                                                onchange={(e) => onUploadMedia(e, e.target.files[0])} />
                                                        </div>
                                                        <div className="other-imgs">
                                                            {
                                                                mediaIds.map((mediaId, i) => (
                                                                    <div className="image" key={i}>
                                                                        <img
                                                                            src={imagesUploaded[mediaId]}
                                                                            alt={mediaId}
                                                                            onClick={(e) => {
                                                                                const clickedImgId = e.target.alt;
                                                                                setFile((prev) => {
                                                                                    if (prev.length > 0 && prev[0].imgId === clickedImgId) {
                                                                                        return [];
                                                                                    } else {
                                                                                        return [{ imgId: clickedImgId, nameImg: "product image" }];
                                                                                    }
                                                                                });
                                                                                setMediaId(clickedImgId)
                                                                                setProductId(product.productId)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="info">
                                                {
                                                    isEditField[index][1] ?
                                                        <Input
                                                            onClick={(e) => { e.stopPropagation() }}
                                                            onClose={(e) => {
                                                                e.stopPropagation()
                                                                setIsEditField(new Array(products.length).fill(null).map(() =>
                                                                    new Array(Object.keys(products[0]).length).fill(false)))
                                                            }}
                                                            value={
                                                                values[index] ?
                                                                    values[index][1].v
                                                                    : null
                                                            }
                                                            onchange={(e) => handleChangeFieldValue(e, index, 1)}
                                                            onConfirm={(e) => onEditCategoryValue(e, product.productId, index, 1)}
                                                        />
                                                        :
                                                        <div>
                                                            <p className="categ">{product?.productCategory}</p>
                                                            <div className="icons">
                                                                <span
                                                                    className={isCopied.index === index && isCopied.field === "productCategory" ? "active" : ""}
                                                                    onClick={
                                                                        (e) => {
                                                                            e.stopPropagation()
                                                                            onCopyValue(product.productCategory, 'productCategory', index)
                                                                        }}
                                                                >
                                                                    <PiCopy
                                                                        fontWeight={"400"}
                                                                        size={"16px"}
                                                                        color={isCopied.index === index && isCopied.field === "productCategory" ? "var(--blue)" : "var(--dark-grey)"} />
                                                                </span>
                                                                <span onClick={(e) => onEditField(e, index, 1)}>
                                                                    <PiPencilSimple
                                                                        fontWeight={"400"}
                                                                        size={"16px"}
                                                                        color="var(--dark-grey)" />
                                                                </span>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="separator">
                                            <div></div>
                                        </div>
                                        <div className="last">
                                            <div className="status">
                                                <div>
                                                    <div className="point" style={product?.productStatus === true ? { backgroundColor: "green" } : { backgroundColor: "red" }}></div>
                                                    {
                                                        product?.productStatus === true ? "online" : "offline"
                                                    }
                                                    <span className={isModifyStatus[index] ? "active" : ""}
                                                        onClick={(e) => onModifyStatus(e, index)}>
                                                        <PiPencilSimple />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="created-at">
                                                <div>
                                                    <p>{(new Date(product?.createdAt).toISOString().split('T')[0]).concat(" / ").concat(new Date(product?.createdAt).toISOString().split('T')[1].slice(0, 5))}</p>
                                                </div>
                                            </div>
                                            <div className={isModifyStatus[index] ? "list-status active" : "list-status"} onClick={(e) => { e.stopPropagation() }}>
                                                <ul>

                                                    <li onClick={() => onPublishProduct(product.productId)} >
                                                        <span>
                                                            <div className={product.productStatus === true ? "radio active" : "radio"}>
                                                                <div></div>
                                                            </div>
                                                        </span>
                                                        online
                                                    </li>
                                                    <li onClick={() => onUnpublishProduct(product.productId)}>
                                                        <span>
                                                            <div className={product.productStatus === false ? "radio active" : "radio"}>
                                                                <div></div>
                                                            </div>
                                                        </span>
                                                        offline
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))
                        }
                    </>
                }
            />
            {
                message && (
                    message.includes("success") ?
                        <div className="message"><p>{message}</p></div>
                        :
                        <div className="message error"><p>{message}</p></div>
                )
            }
        </div>
    )
}
