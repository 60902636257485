import "./input.scss"
import { PiPencilSimple, PiCheck, PiX } from "react-icons/pi"

export default function Input({ value, onchange, onClose, onClick, onConfirm, placeholder }) {
    return (
        <div className="input-container" onClick={onClick}>
            <div className="container">
                <div className="input">
                    <span>
                        <PiPencilSimple color="var(--dark-grey)" />
                    </span>
                    <input
                        type="text"
                        placeholder={placeholder || "edit"}
                        value={value}
                        onChange={onchange}
                    />
                </div>
                <div className="buttons">
                    <span className="check" onClick={onConfirm}>
                        <PiCheck size={"16px"} color="var(--blue)" />
                    </span>
                    <span className="close" onClick={onClose}>
                        <PiX color="var(--dark-grey)" />
                    </span>
                </div>
            </div>

        </div>
    )
}
