import "./sessions-page.scss"
import Tabs from '../Components/Tabs'
import { ReactComponent as LeadsIcon } from "../assets/leads.svg"
import { ReactComponent as ProductsIcon } from "../assets/products.svg"
import { ReactComponent as PagesIcon } from "../assets/pages.svg"
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { Context } from "../App";
import Sessions from "../Sections/Sessions"
export default function SessionsPage() {
    const { isChecked, isAuthenticated } = useContext(Context);
    const navigate = useNavigate();
    useEffect(() => {
        if (isChecked && !isAuthenticated) {
            navigate("/login");
        };
    });
    return (
        <div className='sessions-page'>
            <Tabs titles={[
                { icon: <LeadsIcon />, tabName: "Leads", link: "/manage/leads" },
                { icon: <ProductsIcon />, tabName: "Products", link: "/manage/products" },
                { icon: <PagesIcon />, tabName: "Pages", link: "/manage/pages" }
            ]} />
            <Sessions />
        </div>
    )
}
