import { useCallback, useEffect, useRef } from "react";
import "./drop-down.scss";
import { ReactComponent as CaretUpIcon } from "../assets/caretUp.svg"
export default function DropDown({setIsOpen, isOpen, toggleDropdown , options, handleOptionClick, isCheck, selectedOption }) {
  
    
    const dropDownRef = useRef()
    const handleClickOutside = useCallback((event) => {
        if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    }, [setIsOpen]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
    return (
        <div className="dropdown" ref={dropDownRef}>
            <div onClick={toggleDropdown} className="dropdown-toggle">
                <p>{selectedOption}</p>
                <span className={isOpen ? "active" : ""} onClick={toggleDropdown}><CaretUpIcon /></span>
            </div>
            {isOpen && (
                <ul className="dropdown-menu">
                    {options.map((option, index) => (
                        <li key={index} onClick={() => handleOptionClick(option.title, index, option.formId)}>
                            <div className={isCheck[index]? "check active" :"check"}>
                                <div></div>
                            </div>
                            {option.title}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}
