import { PiCheck, PiCopy, PiHash, PiList, PiPencilSimple } from "react-icons/pi"
import TableComp from "../Components/TableComp"
import "./sessions.scss"
import { useEffect, useState } from "react"
import config from "../config";
import { ReactComponent as CheckIcon } from "../assets/check.svg"

export default function Sessions() {
    const [sessions, setSessions] = useState([]);
    const [isCopied, setIsCopied] = useState({})
    useEffect(() => {
        fetch(config.API_BASE + "/me/sessions/all", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(data => {
                setSessions(data)
            })
            .catch(err => console.log(err))
    }, [])
    const onCopyValue = (text, fieldKey, index) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setIsCopied({ field: fieldKey, index });
                setTimeout(() => setIsCopied({}), 3000);
            })
            .catch((err) => console.error('Failed to copy: ', err));
    };
    return (
        <div className="sessions">
            <TableComp
                header={
                    <>
                        <div className="first">
                            <div className="check">
                                <span>
                                    <PiCheck size={"16px"} color="#CCC" />
                                </span>
                            </div>
                            <div className="list">
                                <span>
                                    <PiList size={"16px"} />
                                </span>
                            </div>
                            <div className="number">
                                <span><PiHash size={"16px"} /></span>
                            </div>
                        </div>
                        <div className="separator">
                            <div></div>
                        </div>
                        <div className="second">
                            <div className="info">
                                <p>Location</p>
                            </div>
                            <div className="info">
                                <p>IP Address</p>
                            </div>
                            <div className="info">
                                <p>Operating System</p>
                            </div>
                            <div className="info">
                                <p>Browser</p>
                            </div>
                        </div>
                        <div className="separator">
                            <div></div>
                        </div>
                        <div className="last">
                            <div className="status">
                                <p>Status</p>
                            </div>
                            <div className="created-at">
                                <p>last accessed</p>
                            </div>
                            <div className="created-at">
                                <p>Created At</p>
                            </div>
                        </div>
                    </>
                }
                body={
                    <>
                        {
                            sessions.map((session, index) => (
                                <div className="session-line" key={index}>
                                    <div className="first">
                                        <div className="check">
                                            <span>
                                                <div className="checkbox">
                                                    <CheckIcon />
                                                </div>
                                            </span>
                                        </div>
                                        <div className="list">
                                            <span>
                                                <PiList color="var(--dark-grey)" />
                                            </span>
                                        </div>
                                        <div className="number">
                                            <span>{session?.serialNumber}</span>
                                        </div>
                                    </div>
                                    <div className="separator">
                                        <div></div>
                                    </div>
                                    <div className="second">
                                        <div className="info">
                                            <div>
                                                <p>{session.data.location.city}, {session.data.location.country}</p>
                                                <div className="icons">
                                                    <span
                                                    className={isCopied.index === index && isCopied.field === "location" ? "active" : ""}
                                                        onClick={(e) => {
                                                            e.stopPropagation()
                                                            onCopyValue((session.data.location.city.concat(", ").concat(session.data.location.country)), 'location', index)
                                                        }}>
                                                        <PiCopy
                                                            fontWeight={"400"}
                                                            size={"16px"}
                                                            color={isCopied.index === index && isCopied.field === "location" ? "var(--blue)" : "var(--dark-grey)"}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <div>
                                                <p>{session.data.ipAddress}</p>
                                                <div className="icons">
                                                    <span
                                                        className={isCopied.index === index && isCopied.field === "ipAdress" ? "active" : ""}
                                                        onClick={
                                                            (e) => {
                                                                e.stopPropagation()
                                                                onCopyValue(session.data.ipAddress, 'ipAdress', index)
                                                            }}>
                                                        <PiCopy
                                                            fontWeight={"400"}
                                                            size={"16px"}
                                                            color={isCopied.index === index && isCopied.field === "ipAdress" ? "var(--blue)" : "var(--dark-grey)"}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <div>
                                                <p>{session.data.os}</p>
                                                <div className="icons">
                                                    <span
                                                        className={isCopied.index === index && isCopied.field === "Operating System" ? "active" : ""}
                                                        onClick={
                                                            (e) => {
                                                                e.stopPropagation()
                                                                onCopyValue(session.data.os, 'Operating System', index)
                                                            }}>
                                                        <PiCopy
                                                            fontWeight={"400"}
                                                            size={"16px"}
                                                            color={isCopied.index === index && isCopied.field === "Operating System" ? "var(--blue)" : "var(--dark-grey)"}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info">
                                            <div>
                                                <p>{(session.data.browser)}</p>
                                                <div className="icons">
                                                    <span
                                                        className={isCopied.index === index && isCopied.field === "Browser" ? "active" : ""}
                                                        onClick={
                                                            (e) => {
                                                                e.stopPropagation()
                                                                onCopyValue((session.data.browser), 'Browser', index)
                                                            }}>
                                                        <PiCopy
                                                            fontWeight={"400"}
                                                            size={"16px"}
                                                            color={isCopied.index === index && isCopied.field === "Browser" ? "var(--blue)" : "var(--dark-grey)"}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="separator">
                                        <div></div>
                                    </div>
                                    <div className="last">
                                        <div className="status">
                                            <div>
                                                <p>{session.isLoggedIn === true ? "Logged In" : "Logges Out"}</p>
                                                <span>
                                                    <PiPencilSimple />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="last-accesed">
                                            <div>
                                                <p>{(new Date(session.lastAccessed)).toLocaleDateString('en-GB')}</p>
                                            </div>
                                        </div>
                                        <div className="created-at">
                                            <div>
                                                <p>{(new Date(session?.createdAt).toISOString().split('T')[0])}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                }
            />
        </div>
    )
}
