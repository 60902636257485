import React, { useContext, useEffect } from 'react'
import { Context } from '../App';
import { useNavigate } from 'react-router-dom';

export default function Home() {
    const { isAuthenticated, isChecked } = useContext(Context);
    const navigate = useNavigate();
    useEffect(() => {
        if (isChecked && !isAuthenticated) {
            navigate("/login");
        } else if (isChecked && isAuthenticated) {
            navigate("/manage/leads");
        };
    });
    return (
        <>
            {isChecked && <p>Redirecting ...</p>}
            {!isChecked && <p>Checking Auth Status ...</p>}
        </>
    )
}