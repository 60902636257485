import './App.scss';
import './global.scss';
import config from './config';
import Login from './Sections/Login';
import Home from './Sections/Home';
import { BrowserRouter, Route } from 'react-router-dom';
import { Routes } from "react-router-dom";
import { createContext, useEffect, useState } from 'react';
import ProductsPage from './Pages/ProductsPage';
import LeadsPage from './Pages/LeadsPage';
import PagesPage from './Pages/PagesPage';
import SessionsPage from './Pages/SessionsPage';
export const Context = createContext(false);


function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    fetch(config.API_BASE + '/auth/check', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then((data) => {
        const { error, success } = data;
        setIsChecked(true);
        if (error) {
          console.log(error);
          setIsAuthenticated(false);
        } else if (success) {
          setIsAuthenticated(true);
        };
      })
      .catch((err) => {
        console.error("Error:", err.message);
        setIsAuthenticated(false);
      });
  }, [isAuthenticated, isChecked]);


  return (
    <Context.Provider value={{ isAuthenticated, isChecked, setIsAuthenticated }}>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/manage/products" element={<ProductsPage />} />
          <Route path="/manage/leads" element={<LeadsPage />} />
          <Route path="/manage/pages" element={<PagesPage />} />
          <Route path="/manage/sessions" element={<SessionsPage />} />
        </Routes>
      </BrowserRouter>

    </Context.Provider>
  );
}

export default App;
