import { ReactComponent as ArrowLeftIcon } from "../assets/arrow-left.svg"
import { ReactComponent as ModifyIcon } from "../assets/modify.svg"
import { ReactComponent as CheckIcon } from "../assets/check.svg"
import "./leads.scss";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import config from "../config";
import DropDown from "../Components/DropDown"
import { PiHash, PiList, PiCheck, PiFunnel, PiCopy, PiPhone, PiEnvelope, PiPencilSimple, PiArrowSquareOut, PiTrayArrowDown, PiTrayArrowUp, PiTilde, PiTrash, PiSortAscending } from 'react-icons/pi';
import PhoneFilter from "../Components/PhoneFilter";
import Input from "../Components/Input";
import TableComp from "../Components/TableComp";

export default function Leads() {
    const [isFilter, setIsFilter] = useState([])
    const [isModify, setIsModify] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null);
    const [isCheck, setIsCheck] = useState([]);
    const [formId, setFromId] = useState("");
    const [listForms, setListForms] = useState([]);
    const [fieldId, setFieldId] = useState("");
    const [formCoutries, setFormCountries] = useState([]);
    const [isSelectedCountries, setIsSelectedCountries] = useState([]);
    const [codeCountries, setCodeCountries] = useState([]);
    const [exportLeadsId, setExportLeadsId] = useState([])
    const [refrech, setRefrech] = useState(false)
    const [isCheckedLead, setIsCheckedLead] = useState([])
    const [isCopied, setIsCopied] = useState([])
    const [isEdit, setIsEdit] = useState([]);
    const [isOpenList, setIsOpenList] = useState(false);
    const [leadId, setLeadId] = useState("")
    const [value, setValue] = useState([])
    const [leads, setLeads] = useState([]);
    const [option, setOption] = useState("all")
    const [message, setMessage] = useState(null);
    const [theme, setTheme] = useState("");
    const [isExport, setIsExport] = useState(false)
    const [isActiveFilter, setIsActiveFilter] = useState(false)
    const [archivedCount, setArchivedCount] = useState("");
    const [unarchivedCount, setUnarchivedCount] = useState("");
    const [isSortedField, setIsSortedField] = useState([])
    const [fieldIdToSort, setFieldIdToSort] = useState(null);

    useEffect(() => {
        if (listForms.length === 0) {
            return;
        }
        if (!selectedForm) {
            return;
        }
        setIsSortedField((listForms?.filter(t => t.title === selectedForm)?.[0].fields)?.map(() => false))
        setIsFilter((listForms?.filter(t => t.title === selectedForm)?.[0].fields)?.map(() => false))
    }, [selectedForm, listForms])
    const onSort = useCallback((index, fieldId) => {
        setIsSortedField(prev => {
            const newValues = prev.map((item, i) => (i === index ? !item : false));
            const newIsSorted = newValues[index];

            setFieldIdToSort(newIsSorted ? fieldId : null);

            return newValues;
        });
    }, [setFieldIdToSort]);
    console.log(fieldIdToSort)
    const onFilter = useCallback((index, fieldId) => {
        const newFilter = [...isFilter];
        newFilter[index] = !newFilter[index];
        setIsFilter(newFilter);
        setFieldId(fieldId);
    }, [isFilter])
    const onModify = (e, index) => {
        e.stopPropagation();
        setIsModify(prev => {
            let newValues = prev.map((item, i) => (i === index ? !item : false));
            return newValues;
        });
    }
    const onCheck = (index, leadId) => {
        setIsCheckedLead(prev => {
            const newValues = [...prev];
            newValues[index] = !newValues[index];
            return newValues;
        });
        setLeadId(leadId)

    };
    useEffect(() => {
        if (isCheckedLead.every(Boolean)) {
            const allLeadIds = leads.map(lead => lead.leadId);
            setExportLeadsId(allLeadIds);
        }
        else if (isCheckedLead.some(Boolean)) {
            setExportLeadsId(prev => {
                const newValues = prev.includes(leadId)
                    ? prev.filter(id => id !== leadId)
                    : [...prev, leadId];
                return newValues;
            });
        }
        else {
            setExportLeadsId([])
        }
    }, [isCheckedLead, leads, leadId]);

    useEffect(() => {
        const formTarget = new URL(config.API_BASE + "/forms/list");
        fetch(formTarget, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include"
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setListForms(data)
            });
    }, [])
    useEffect(() => {
        const initialChecks = new Array(listForms.length).fill(false);
        if (initialChecks.length > 0) {
            initialChecks[0] = true;
        }
        setSelectedForm(listForms[0]?.title)
        setIsCheck(initialChecks);
        setFromId(listForms[0]?.formId)
    }, [listForms]);
    const handleSelectForm = (option, index, id) => {
        setSelectedForm(option);
        setFromId(id)
        setIsCheck((prev) => {
            const newValues = prev.map((item, i) => (i === index ? !item : false));
            const hasTrueValue = newValues.some(value => value === true);
            return hasTrueValue ? newValues : prev;
        })
    };

    const getOption = (value) => {
        setOption(value)
    }
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(1)
    const limit = 10;
    const onAddPage = () => {
        setPage(page + 1)
    }
    const onMinusPage = () => {
        setPage(page - 1)
    }
    useEffect(() => {
        setSkip((page - 1) * limit)
    }, [page])
    useEffect(() => {
        setSkip(0);
        setPage(1)
    }, [option, isCheck])
    useEffect(() => {
        if (!formId) {
            return;
        }
        const params = new URLSearchParams();
        params.set("formId", formId);
        params.set("option", option);
        params.set("limit", limit);
        params.set("skip", skip);
        if (fieldId) {
            params.set("fieldIdToFilter", fieldId);
        }
        if (fieldId && isActiveFilter && codeCountries.length) {
            params.set("filters", JSON.stringify(codeCountries))
        }
        if (fieldIdToSort) {
            params.set("fieldIdToSort", fieldIdToSort)
            params.set("ascending", true)
            params.set("sortedLeads", true)
        }
        const target = config.API_BASE + "/leads/list?" + params.toString();
        fetch(target, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include"
        })
            .then(res => res.json())
            .then(res => {
                setLeads(res.leads);
                setUnarchivedCount(res.unarchivedCount)
                setArchivedCount(res.archivedCount)
                setIsModify(new Array(res.leads.length).fill(false))
                setIsCheckedLead(new Array(res.leads.length).fill(false))
                setIsCopied(new Array(res.leads.length).fill(null).map(() =>
                    new Array(Object.keys(res.leads[0].leadData).length).fill(false)
                ))
                setIsEdit(new Array(res.leads.length).fill(null).map(() =>
                    new Array(Object.keys(res.leads[0].leadData).length).fill(false)
                ))
                setIsOpenList(new Array(res.leads.length).fill(false))
                setValue(res.leads.map(lead => {
                    return (
                        Object.entries(lead.leadData).map(([k, v]) => {
                            const fieldName = listForms?.find((f) => f.formId === formId)?.fields?.find(f => f.name === k)?.name;
                            const fieldType = listForms?.find((f) => f.formId === formId)?.fields?.find(f => f.name === k)?.type;
                            let fieldValue = v;
                            if (fieldName === "email") {
                                fieldValue = v?.local + "@" + v?.domain
                            }
                            if (fieldType === "phone") {
                                return fieldValue = v?.code + v?.number.national
                            }
                            else {
                                return fieldValue
                            }
                        }
                        ))
                }))
                setExportLeadsId([])
                setIsOpen(false)
            })
    }, [formId, option, refrech, listForms, fieldId, codeCountries, isActiveFilter, skip, fieldIdToSort]);

    const onEditStatusLead = (leadId, status) => {
        const editTarget = `${config.API_BASE}/leads/lead/status`;
        fetch(editTarget, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                leadId: leadId,
                statusName: status
            })
        })
            .then(response => {
                response.json()
                setRefrech(!refrech)
                if (response.ok) {
                    setMessage("Lead Status updated successfully")
                    setTimeout(() => {
                        setMessage(null)
                    }, 3000)
                }
                else {
                    setMessage("Error updating Lead Status")
                    setTimeout(() => {
                        setMessage(null)
                    }, 3000)
                }
            })
            .catch(err => {
                console.error(err);
            })
    }

    const OnCopyValue = (e, value, leadIndex, fieldIndex) => {
        e.stopPropagation();
        navigator.clipboard.writeText(value)
            .then(() => {
                const updatedIsCopied = isCopied.map((row, index) => {
                    if (index === leadIndex) {
                        const newRow = [...row];
                        newRow[fieldIndex] = true;
                        return newRow;
                    }
                    return row;
                });

                setIsCopied(updatedIsCopied);
                setTimeout(() => {
                    setIsCopied(prevState =>
                        prevState.map((row, index) => {
                            if (index === leadIndex) {
                                const newRow = [...row];
                                newRow[fieldIndex] = false;
                                return newRow;
                            }
                            return row;
                        })
                    );
                }, 3000);
            });
    };

    const onEditValue = (e, leadIndex, fieldIndex) => {
        e.stopPropagation();
        setIsEdit(() => {
            return isEdit.map((row, index) => {
                return index === leadIndex
                    ? row.map(() => false).fill(false, 0, row.length).fill(true, fieldIndex, fieldIndex + 1)
                    : row.map(() => false);
            });
        });
    }

    const onOpenList = (e, index) => {
        e.stopPropagation()
        setIsOpenList(prev => {
            let newValues = prev.map((item, i) => (i === index ? !item : false));
            return newValues;
        });
    }

    const onArchivLead = (e, leadId) => {
        e.stopPropagation();
        const archiveTarget = config.API_BASE + "/leads/lead/archive";
        fetch(archiveTarget, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                leadId: leadId
            })
        }).then(response => {
            response.json()
            setRefrech(!refrech)
            setMessage("lead archived successfully")
            setTimeout(() => {
                setMessage(null)
            }, 4000)
        })
            .catch(err => {
                console.error(err);
            })
    }
    const onUnarchivLead = (e, leadId) => {
        e.stopPropagation();
        const archiveTarget = config.API_BASE + "/leads/lead/unarchive";
        fetch(archiveTarget, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                leadId: leadId
            })
        }).then(response => {
            response.json()
            setRefrech(!refrech)
            setMessage("lead unarchived successfully")
            setTimeout(() => {
                setMessage(null)
            }, 4000)
        })
            .catch(err => {
                console.error(err);
            })
    }
    const handleChangeValue = (e, leadIndex, fieldIndex) => {
        setValue(prev => {
            const newValue = [...prev];
            newValue[leadIndex][fieldIndex] = e.target.value
            return newValue
        })
    }

    useEffect(() => {
        if (isCheckedLead.every(item => item === true)) {
            setTheme("all");
        } else if (isCheckedLead.some(item => item === true)) {
            setTheme("almost");
        }
        else {
            setTheme("");
        }
    }, [isCheckedLead]);

    const handleSelectAll = useCallback(() => {
        setIsCheckedLead(prevState => {
            const allChecked = prevState.every(item => item === true);
            return prevState.map(() => !allChecked);
        });
    }, [setIsCheckedLead]);

    const onSelectAllCountries = useCallback(() => {
        setIsSelectedCountries(
            prevState => {
                const allSelected = prevState.every(item => item === true);
                return prevState.map(() => !allSelected);
            }
        )
    }, [setIsSelectedCountries])

    useEffect(() => {
        handleSelectAll()
        onSelectAllCountries()
    }, [handleSelectAll, onSelectAllCountries])

    useEffect(() => {
        if (!formId) {
            return;
        }
        const countriesTarget = config.API_BASE + "/forms/countries?formId=" + formId;
        fetch(countriesTarget, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: "include"
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                else {
                    throw new Error(response.statusText);
                }
            })
            .then(data => {
                setFormCountries(data);
                setIsSelectedCountries(new Array(data.length).fill(false))
            })
            .catch(err => {
                console.error(err)
            })
    }, [formId])

    const onSelectCountries = (codeCountry, index) => {
        setIsSelectedCountries(prev => {
            const newValue = [...prev];
            newValue[index] = !newValue[index];
            return newValue;
        });
        setCodeCountries(prev => {
            const newValue = [...prev];
            const filterValue = newValue.filter(n => n !== codeCountry);
            if (filterValue.length === newValue.length) {
                return [...newValue, codeCountry]
            }
            return filterValue;
        });
    }

    const onExportData = async () => {
        const response = await fetch(config.API_BASE + '/leads/export', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify({
                "leadIds": exportLeadsId,
                "format": "csv"
            })
        });
        const csvData = await response.text();
        setRefrech(!refrech)
        setIsExport(false)
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'leads.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const toggleButton = (fieldType) => {
        if (fieldType === "phone") {
            setIsActiveFilter(!isActiveFilter)
        }
        else {
            setIsActiveFilter(false)
        }
    }

    const OnDeleteLead = (e, leadId) => {
        e.stopPropagation();
        setRefrech(!refrech)
        fetch(config.API_BASE + "/leads/lead/delete", {
            method: "DELETE",
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: "include",
            body: JSON.stringify({ leadId: leadId })
        }).then(response => response.json())
            .then(data => {
                setMessage("Lead deleted successfully")
                setTimeout(() => {
                    setMessage(null)
                }, 3000)
                console.log(data)
            })
            .catch(err => console.log(err))
    }

    const totalPages = useMemo(() => {
        switch (option) {
            case "all":
                return archivedCount + unarchivedCount
            case "archived":
                return archivedCount
            case "main":
                return unarchivedCount
            default:
                break;
        }
    }, [archivedCount, unarchivedCount, option])

    return (
        <div className='leads'>
            <div className="sub-nav-bar">
                <DropDown
                    options={listForms}
                    handleOptionClick={handleSelectForm}
                    isCheck={isCheck}
                    selectedOption={selectedForm}
                    isOpen={isOpen}
                    toggleDropdown={toggleDropdown}
                    setIsOpen={setIsOpen}
                />
                <div className="filter">
                    <div className={option === "main" ? "active" : ""} onClick={() => getOption("main")}>Main
                        <span>
                            ({unarchivedCount || 0})
                        </span>
                    </div>
                    <div className={option === "archived" ? "active" : ""} onClick={() => getOption("archived")}>Archive
                        <span>
                            ({archivedCount || 0})
                        </span>
                    </div>
                    <div className={option === "all" ? "active" : ""} onClick={() => getOption("all")}>All
                        <span>
                            ({archivedCount + unarchivedCount || 0})
                        </span>
                    </div>
                </div>
                <div className="pagination">
                    <p>Page {page} of {Math.ceil(totalPages / limit)}</p>
                    <div>
                        <span className={page === 1 ? "left disabled" : "left"} onClick={onMinusPage}><ArrowLeftIcon /></span>
                        <span className={leads.length < limit ? "right disabled" : "right"} onClick={onAddPage}><ArrowLeftIcon /></span>
                    </div>
                </div>
            </div>
            <TableComp
                header={
                    <>
                        {
                            listForms?.filter(f => f.title === selectedForm)
                                .map((form, index) => (
                                    <Fragment key={index}>
                                        <div className="first">
                                            <div className="check">
                                                <span className={theme} onClick={handleSelectAll}>
                                                    {
                                                        theme === "almost" ?
                                                            <PiTilde size={"16px"} color="var(--blue)" />
                                                            :
                                                            <PiCheck
                                                                size={"16px"}
                                                                color={theme === "all" ? "var(--blue)" : "#CCC"}
                                                            />
                                                    }
                                                </span>
                                            </div>
                                            <div className="list">
                                                <span onClick={() => { setIsExport(p => !p) }}>
                                                    <PiList size={"16px"} />
                                                </span>
                                                {
                                                    isExport &&
                                                    <div className="items">
                                                        <div className="item" onClick={onExportData}>
                                                            <span>
                                                                <PiArrowSquareOut size={"16px"} />
                                                            </span>
                                                            <p>Export (CSV)</p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="number">
                                                <span><PiHash size={"16px"} /></span>
                                            </div>
                                        </div>
                                        <div className="separator">
                                            <div></div>
                                        </div>
                                        <div className="second">
                                            {
                                                form.fields.map((field, index) => (
                                                    <div key={index} className="info">
                                                        <p>{field.title}
                                                            <span className="icons-container">
                                                                <span className={isSortedField[index] ? "active" : ""}
                                                                    onClick={() => onSort(index, field._id)}
                                                                >
                                                                    <PiSortAscending size={"16px"}
                                                                        color={isSortedField[index] ? "var(--blue)" : "var(--dark-grey)"}
                                                                    />
                                                                </span>
                                                                {
                                                                    (
                                                                        field.type === "email"
                                                                        ||
                                                                        field.type === "phone"
                                                                    )
                                                                    &&
                                                                    <span
                                                                        id={`filter-span-${index}`}
                                                                        onClick={() => {
                                                                            onFilter(index, field._id)
                                                                        }}
                                                                        className={(isFilter[index] || (field.type === "phone" ? isActiveFilter : null)) === true ? "active" : ""}>
                                                                        <PiFunnel
                                                                            size={"16px"}
                                                                            color={(isFilter[index] || (field.type === "phone" ? isActiveFilter : null)) ? "var(--blue)" : "var(--dark-grey)"} />
                                                                    </span>
                                                                }
                                                                {
                                                                    isFilter[index] === true
                                                                    &&
                                                                    field.type === "phone"
                                                                    &&
                                                                    <PhoneFilter
                                                                        options={formCoutries.concat([{ countryNameFR: { common: "other" }, countryCode: "other", flagURL: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjU2IDI1NiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNMTU2IDEyOGEyOCAyOCAwIDEgMS0yOC0yOGEyOCAyOCAwIDAgMSAyOCAyOE00OCAxMDBhMjggMjggMCAxIDAgMjggMjhhMjggMjggMCAwIDAtMjgtMjhtMTYwIDBhMjggMjggMCAxIDAgMjggMjhhMjggMjggMCAwIDAtMjgtMjgiLz48L3N2Zz4=" }])}
                                                                        isSelectedCountries={isSelectedCountries}
                                                                        onSelectCountries={onSelectCountries}
                                                                        isOn={isActiveFilter}
                                                                        toggleButton={() => toggleButton(field.type)}
                                                                        setIsFilter={setIsFilter}
                                                                        onSelectAll={onSelectAllCountries}
                                                                    />
                                                                }
                                                            </span>

                                                        </p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className="separator">
                                            <div></div>
                                        </div>
                                        <div className="last">
                                            <div className="status">
                                                <p>Status</p>
                                            </div>
                                            <div className="created-at">
                                                <p>Created at</p>
                                            </div>
                                        </div>
                                    </Fragment>
                                ))
                        }
                    </>
                }
                body={
                    <>
                        {
                            (listForms.length !== 0 &&
                                leads.length !== 0) ?
                                (
                                    leads?.map((lead, index) => (
                                        <div className={isCheckedLead[index] ? "line active" : "line"} onClick={() => onCheck(index, lead.leadId)} key={index}>
                                            <div className="first">
                                                <div className="check">
                                                    <span>
                                                        <div className="checkbox">
                                                            <CheckIcon />
                                                        </div>
                                                    </span>
                                                </div>
                                                <div className="list">
                                                    <span className={isOpenList[index] ? "active" : ""}
                                                        onClick={(e) => { onOpenList(e, index) }}>
                                                        <PiList color={isOpenList[index] ? "var(--blue)" : "var(--dark-grey)"} />
                                                    </span>
                                                    {
                                                        isOpenList[index] &&
                                                        (
                                                            <div className="list-items">
                                                                <div className="item">
                                                                    <span>
                                                                        <PiArrowSquareOut size={"16px"} />
                                                                    </span>
                                                                    <p>Export (CSV)</p>
                                                                </div>
                                                                {
                                                                    lead.isArchived === true ?

                                                                        <div className="item" onClick={(e) => { onUnarchivLead(e, lead.leadId) }}>
                                                                            <span>
                                                                                <PiTrayArrowUp size={"16px"} />
                                                                            </span>
                                                                            <p>
                                                                                Unarchive
                                                                            </p>
                                                                        </div>
                                                                        :
                                                                        <div className="item" onClick={(e) => { onArchivLead(e, lead.leadId) }}>
                                                                            <span>
                                                                                <PiTrayArrowDown size={"16px"} />
                                                                            </span>
                                                                            <p>
                                                                                Archive
                                                                            </p>
                                                                        </div>
                                                                }
                                                                <div className="item delete" onClick={(e) => OnDeleteLead(e, lead.leadId)}>
                                                                    <span>
                                                                        <PiTrash size={"16px"} color="red" />
                                                                    </span>
                                                                    <p>Delete</p>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className="number">
                                                    <span>{lead.serialNumber}</span>
                                                </div>
                                            </div>
                                            <div className="separator">
                                                <div></div>
                                            </div>
                                            <div className="second">
                                                {
                                                    Object.entries(lead.leadData).map(([k, v], i) => {
                                                        let fieldName = "";
                                                        let fieldType = "";
                                                        let fieldValue = "";
                                                        const form = listForms?.find(f => f.formId === formId);
                                                        if (!form) {
                                                            return <></>;
                                                        } else {
                                                            const field = form.fields?.find(f => f.name === k);
                                                            if (!field) {
                                                            } else {
                                                                fieldName = field.name;
                                                                fieldType = field.type;
                                                                fieldValue = v;
                                                                if (fieldName === "email") {
                                                                    fieldValue = v?.local + "@" + v?.domain;
                                                                } else if (fieldType === "phone") {
                                                                    fieldValue = `+${v?.code} ${v?.number?.national}`;
                                                                }
                                                            }
                                                        }
                                                        return (
                                                            <div className="info" key={k}>
                                                                {
                                                                    isEdit[index][i] ?
                                                                        <Input
                                                                            onClick={(e) => { e.stopPropagation() }}
                                                                            onClose={(e) => {
                                                                                e.stopPropagation()
                                                                                setIsEdit(new Array(leads.length).fill(null).map(() =>
                                                                                    new Array(Object.keys(leads[0].leadData).length).fill(false)))
                                                                            }}
                                                                            value={
                                                                                value[index] ?
                                                                                    value[index][i]
                                                                                    : null
                                                                            }
                                                                            onchange={(e) => handleChangeValue(e, index, i)}
                                                                        />
                                                                        :
                                                                        <div>
                                                                            <p>
                                                                                {fieldValue}
                                                                            </p>
                                                                            <div className="icons">
                                                                                <span className={isCopied[index][i] ? "active" : ""}
                                                                                    onClick={(e) =>
                                                                                        OnCopyValue(e, fieldValue, index, i)
                                                                                    }>
                                                                                    <PiCopy fontWeight={"400"} size={"16px"} color={isCopied[index][i] ? "var(--blue)" : "var(--dark-grey)"} />
                                                                                </span>
                                                                                {
                                                                                    fieldName === "email" &&
                                                                                    <span>
                                                                                        <PiEnvelope fontWeight={"400"} size={"16px"} color="var(--dark-grey)" />
                                                                                    </span>
                                                                                }
                                                                                {
                                                                                    fieldType === "phone" &&
                                                                                    <span>
                                                                                        <PiPhone fontWeight={"400"} size={"16px"} color="var(--dark-grey)" />
                                                                                    </span>
                                                                                }
                                                                                <span onClick={(e) => { onEditValue(e, index, i) }}>
                                                                                    <PiPencilSimple fontWeight={"400"} size={"16px"} color="var(--dark-grey)" />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                    )
                                                }
                                            </div>
                                            <div className="separator">
                                                <div></div>
                                            </div>
                                            <div className="last">
                                                <div className="status">
                                                    <div>
                                                        <div className="point"
                                                            style={{
                                                                backgroundColor: listForms?.find(f => f.formId === formId)
                                                                    ?.statusList?.find(s => s.name === lead.leadStatus)?.color
                                                            }}></div>
                                                        {lead.leadStatus}
                                                        <span onClick={(e) => onModify(e, index)}
                                                            className={isModify[index] ? "active" : ""}>
                                                            <ModifyIcon />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="created-at">
                                                    <div>
                                                        <p>05 09 2024</p>
                                                    </div>
                                                </div>
                                                <div className={isModify[index] ? "list-status active" : "list-status"} onClick={(e) => { e.stopPropagation() }}>
                                                    <ul>
                                                        {listForms?.find(f => f.formId === formId)
                                                            ?.statusList?.map((status, ind) => {
                                                                return (
                                                                    <li key={ind} onClick={() => {
                                                                        onEditStatusLead(lead.leadId, status?.name)
                                                                    }}>
                                                                        <span>
                                                                            <div className={(lead.leadStatus === status.title.toLowerCase()) ? "radio active" : "radio"}>
                                                                                <div></div>
                                                                            </div>
                                                                        </span>
                                                                        {status?.title}
                                                                    </li>
                                                                )
                                                            })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    ))
                                :
                                <div className="no-lead">
                                    Aucun lead generer par ce formulaire
                                </div>
                        }
                    </>
                }
            />
            {
                message && (
                    message.includes("success") ?
                        <div className="message"><p>{message}</p></div>
                        :
                        <div className="message error"><p>{message}</p></div>
                )
            }
        </div>
    )
}
