import "./phone-filter.scss";
import { PiMagnifyingGlass, PiTilde, PiCheck } from "react-icons/pi"
import ToggleButton from "./ToggleButton";
import { useCallback, useEffect, useRef, useState } from "react";

export default function PhoneFilter({onSelectAll, setIsFilter, options, isSelectedCountries, onSelectCountries, isOn, toggleButton }) {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState(options);
    const handleChange = (event) => {
        const value = event.target.value;
        setInputValue(value);

        if (value) {
            const filteredSuggestions = options.filter(option =>
                option.countryNameFR.common.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions(options);
        }
    };

    const filterRef = useRef()
    const handleClickOutside = useCallback((event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {

            setIsFilter(prev => {
                const newFilter = [...prev];
                newFilter[3] = false;
                return newFilter
            });
        }
    }, [setIsFilter]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const [theme, setTheme] = useState("")
    useEffect(() => {
        if (isSelectedCountries.every(item => item === true)) {
            setTheme("all");
        } else if (isSelectedCountries.some(item => item === true)) {
            setTheme("almost");
        }
        else {
            setTheme("");
        }
    }, [isSelectedCountries]);
    return (
        <div className="phone-filter" ref={filterRef}>
            <div className="search-input">
                <span>
                    <PiMagnifyingGlass color="var(--dark-grey)" size={"12px"} />
                </span>
                <input type="search"
                    placeholder="Search...."
                    value={inputValue}
                    onChange={handleChange} />
            </div>
            <div className="container">
                <div className="header">
                    <div className="check">
                        <div className={theme} onClick={onSelectAll}>
                            {
                                theme === "almost" ?
                                    <PiTilde size={"12px"} color="var(--blue)" />
                                    :
                                    <PiCheck
                                        size={"12px"}
                                        color={theme === "all" ? "var(--blue)" : "#CCC"}
                                    />
                            }
                        </div>
                        <p>All</p>
                    </div>
                    <ToggleButton isOn={isOn} toggleButton={toggleButton} />
                </div>
                <div className="options">
                    {
                        suggestions.map((option, index) => (
                            <div key={index} className={isSelectedCountries[index] ? "option-container active" : "option-container"}
                                onClick={() => { onSelectCountries(option?.countryCode, index) }}>
                                <div className="option-check">
                                    <PiCheck size={"14px"} color={isSelectedCountries[index] ? "var(--white)" : "var(--grey)"} />
                                </div>
                                <div className="option">
                                    <span className="option-flag">
                                        <img src={option?.flagURL} alt={`${option?.countryNameFR.common} flag`} />
                                    </span>
                                    <p className="option-name">
                                        {option?.countryNameFR.common}
                                    </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}
